<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >商务名称：
              <el-autocomplete
                class="ipt_width project_width"
                v-model="parameter.businessName"
                :fetch-suggestions="businessNameQuerySearch"
                placeholder="请输入或选择商务名称"
                @select="onProkectNameSelect"
                :popper-append-to-body="false"
              >
              </el-autocomplete
            ></span>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset"> 清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="activeName" class="fullScreenMainHeader">
            <el-tab-pane label="已申请" name="0"></el-tab-pane>
            <el-tab-pane label="未通过" name="1"></el-tab-pane>
            <el-tab-pane label="已通过" name="2"></el-tab-pane>
          </el-tabs>

          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :key="Math.random()"
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              min-width="320"
              :show-overflow-tooltip="false"
              prop="businessName"
              label="商务名称"
            ></el-table-column>
            <!-- <el-table-column align="center" prop="businessNumber" label="商务编号"></el-table-column> -->
            <el-table-column
              align="center"
              prop="businessType"
              sortable
              label="商务类型"
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="contacts"
              label="经办人"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="contactsPhone"
              label="经办人电话"
              width="120"
            ></el-table-column>
            <el-table-column align="center" prop="status" label="状态" sortable width="160">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0">暂存</span>
                <span v-if="scope.row.status == 1 && scope.row.type == null">提交</span>
                <span v-if="scope.row.status == 1 && scope.row.type == 'XSH'"
                  >商务部高管审核中</span
                >
                <span v-if="scope.row.status == 1 && scope.row.type == 'ZGH'">董事长审核中</span>
                <span v-if="scope.row.status == 3 && scope.row.type == 'XSH'"
                  >商务部高管审核通过</span
                >
                <span v-if="scope.row.status == 3 && scope.row.type == 'ZGH'">董事长审核通过</span>
                <span v-if="scope.row.status == 5">已移交计划经营部</span>
                <span v-if="scope.row.status == 6">已创建项目</span>
                <!-- <span v-if="scope.row.status == 7">项目完结</span> -->
                <!-- <span v-if="scope.row.status == 8">商务失败</span> -->
                <span v-if="scope.row.status == 9">商务暂停</span>
                <span v-if="scope.row.status == 10">商务终结</span>
                <span v-if="scope.row.status == 21 && scope.row.type == 'XSH'">高管审核不通过</span>
                <span v-if="scope.row.status == 21 && scope.row.type == 'ZGH'"
                  >董事长审核不通过</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable
              prop="createdDate"
              label="申请时间"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              width="90"
              align="center"
              v-if="activeName == '1'"
              prop="auditUserName"
              label="审核人"
            ></el-table-column>
            <el-table-column
              width="110"
              sortable
              align="center"
              v-if="activeName != '0'"
              prop="auditDate"
              label="审核时间"
            >
              <template slot-scope="scope">
                {{ scope.row.auditDate | dateFormat }}
              </template>
            </el-table-column>

            <el-table-column prop="title" fixed="right" align="center" label="操作" width="150">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="text_Edit_Bgc"
                  size="small"
                  @click="edit(scope.row)"
                  v-if="edit_status(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  v-if="scope.row.status != 0"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Remove_Bgc"
                  @click.stop="delFn(scope.row.id)"
                  v-if="scope.row.status == 1 || scope.row.status == 0 || scope.row.status == 21"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit v-if="showEditDialog" :isShow.sync="showEditDialog" :options="form"></Edit>
    <!-- <ManageView
      v-if="showDetailDialog"
      :isShow.sync="showDetailDialog"
      :options="form"
      :ActiveName="activeName"
      :showWithdraw="withdraw"
    ></ManageView> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
    ManageView: () => import('@/views/publicView/ManageView.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        businessName: null,
      },
      pageSize: 0,
      condition: {
        title: '',
      },
      form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      showDetailDialog: false,
      activeName: '0',
      dictData: {
        businessType: [],
      },
      withdraw: true,
      businessList: [],
    }
  },
  watch: {
    activeName: {
      handler: function (newVal) {
        if (newVal) {
          this.parameter.pageNow = 1
          this.getData()
        }
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.businessManage
      .getApplyForList({ pageSize: 10000, initiatorId: this.userInfo.staffId })
      .then(res => {
        this.loading = false
        if (!res.data) {
          this.businessList = []
        } else {
          this.businessList = res.data.records
        }
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
  },
  methods: {
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },
    delFn(id) {
      this.$confirm('此操作将删除该商务信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true
          this.$api.businessManage
            .deleteBusiness(id)
            .then(res => {
              this.loading = false
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    getData() {
      this.loading = true
      let obj = {
        status: this.activeName,
        initiatorId: this.userInfo.staffId,
        ...this.parameter,
      }

      this.$api.businessManage
        .getApplyForList(obj)
        .then(res => {
          this.loading = false
          if (!res.data) {
            this.tableData = []
            this.parameter.total = 0
          } else {
            this.tableData = res.data.records
            this.parameter.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    add() {
      this.form = {
        list_s: 1,
        status: '0',
      }
      this.showEditDialog = true
    },
    detail(row) {
      this.form = row
      this.form.list_s = 2
      // this.showDetailDialog = true
      this.showEditDialog = true
    },
    edit(row) {
      this.form = row
      this.form.list_s = 3
      this.showEditDialog = true
    },
    re() {
      this.getData()
      this.showEditDialog = false
      this.showDetailDialog = false
      this.form = {}
    },
    edit_status(row) {
      if (row.status == '0' || row.status == '21') {
        return true
      } else {
        return false
      }
    },
    handleReset() {
      this.parameter = {
        pageNow: 1,
        pageSize: this.pageSize,
        total: 0,
        businessName: null,
      }
      this.getData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.text_no_overflow {
  white-space: normal;
  overflow: visible;
}
</style>
